.App {
  width: 100vw;
  height: 100vh;
}

.login-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
